import React, { useContext } from "react";
import Footer from "../../Containers/Footer/Footer";
import Header from "../../Containers/Header/Header";
// import LeaguesAndCategories from "../../Containers/LeaguesAndCategories/LeaguesAndCategories";
import NewsAndLiveMatches from "../../Containers/NewsAndLiveMatches/NewsAndLiveMatches";
import SearchLeaguesAndCategories from "../../Containers/SearchLeaguesAndCategories/SearchLeaguesAndCategories";
import { MatchesContext } from "../../Context/MatchesContext";
import classes from "./Layout.module.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdComponent from "../AdComponent/AdComponent";
import { AdContext } from "../../Context/AdContext";

const Layout = (props) => {
  // context

  const { setSearchTerm, searchTerm } = useContext(MatchesContext);
  const { homePageAds } = useContext(AdContext);
  return (
    <>
      <div className={classes.outerContainer}>
        <div></div>
        <div className={classes.container}>
          <div className={classes.header}>
            <Header />
          </div>
          <div className={classes.body}>
            <div className={classes.leftSection}>
              {!props.notDisplaySearch && (
                <div className={classes.searchSection}>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    // onFocus={() => {
                    //   setDisplaySearchComponent(true);
                    // }}
                    // onBlur={() => {
                    //   if (searchTerm.length < 1) {
                    //     setDisplaySearchComponent(false);
                    //   } else {
                    //     setDisplaySearchComponent(true);
                    //   }
                    // }}
                  />
                  <i>
                    <FontAwesomeIcon icon={faSearch} />
                  </i>
                </div>
              )}

              <SearchLeaguesAndCategories searchTerm={searchTerm} />
            </div>
            <div className={classes.middleSection}>
              <div className={classes.children}>{props.children}</div>
            </div>
            <div className={classes.rightSection}>
              <NewsAndLiveMatches />
            </div>
            <div className={classes.adContainer}>
              <AdComponent
                adArray={homePageAds}
                section="1024px-by-90px-stick-at-the-bottom-of-every-page"
              />
            </div>

            <div className={classes.mobileAdContainer}>
              <AdComponent width="320px" height="50px" />
            </div>
          </div>
          <div className={classes.footer}>
            <Footer />
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default Layout;
