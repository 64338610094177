import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

const TeamLogo = (props) => {
  const [logo, setLogo] = useState();
  const [logoIsLoading, setLogoIsLiading] = useState(false);
  const refId = useRef();

  const fetchImage = async () => {
    const currentId = refId.current;
    // setLogo(null);
    setLogoIsLiading(true);

    if (currentId !== props.id) {
      setLogoIsLiading(false);

      return;
    }

    try {
      const response = await axios.get(
        // `https://eapi.enetpulse.com/image/team_logo/?teamFK=${props.id}&username=${enetPulseUsername}&token=${enetPulseTokenId}`
        `https://api.sportdm.com/api/v1/get_teamlogo/${props.id}`
      );
      if (currentId === refId.current) {
        let imageCode = [];
        if (response.data) {
          imageCode = Object.values(response.data.images)[0].value;
        }
        setLogo(`data:image/png;base64,${imageCode}`);
        setLogoIsLiading(false);
      }
    } catch (error) {
      setLogoIsLiading(false);
    }
  };
  useEffect(() => {
    // setLogoIsLiading(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refId.current = props.id;
    fetchImage();

    // eslint-disable-next-line
  }, [props.id]);

  const navigate = useNavigate();

  if (logoIsLoading) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          size="1rem"
          color="inherit"
          style={{ color: "#ffd91b" }}
        />
      </div>
    );
  }

  if (!logo && !logoIsLoading) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "transparent",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
    );
  }

  return (
    <img
      width={"100%"}
      height={"100%"}
      src={logo}
      alt="Logo"
      onClick={() => {
        navigate(`/team/${props.id}/players`);
      }}
      style={{ cursor: "pointer" }}
    />
  );
};

export default TeamLogo;
