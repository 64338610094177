import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";

export const AdContext = createContext();

const AdContextProvider = (props) => {
  // States
  const [homePageAds, setHomePageAds] = useState([]);
  const [scoresPageAds, setScoresPageAds] = useState([]);
  const [matchDetailsAds, setMatchDetailsAds] = useState([]);

  // Ftech functions
  const fetchHomePageAds = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/filterPage?page=all-pages&country=NG&platform=goalnownow`
      )
      .then((res) => {
        // console.log(res);
        setHomePageAds(res.data.data);
      })
      .then((err) => {});
  };

  const fetchScoresPageAds = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/filterPage?page=scores-home-page&country=NG&platform=goalnownow`
      )
      .then((res) => {
        // console.log(res);
        setScoresPageAds(res.data.data);
      })
      .then((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchHomePageAds();
    fetchScoresPageAds();
  }, []);

  const fetchMatchDetailsPageAds = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/filterPage?page=match-details-display-page&country=NG&platform=goalnownow`
      )
      .then((res) => {
        console.log(res, "match details");
        setMatchDetailsAds(res.data.data);
      })
      .then((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchHomePageAds();
    fetchScoresPageAds();
    fetchMatchDetailsPageAds();
  }, []);

  return (
    <AdContext.Provider value={{ homePageAds, scoresPageAds, matchDetailsAds }}>
      {props.children}
    </AdContext.Provider>
  );
};

export default AdContextProvider;
