import React, { useContext, useEffect } from "react";
import ScorePageLayout from "../../Components/ScorePageLayout/ScorePageLayout";
import { MatchesContext } from "../../Context/MatchesContext";
import LeagueMatchContainer from "./LeagueMatchContainer";
import { LinearProgress } from "@mui/material";
import classes from "./ScorePageMatches.module.css";
import AdComponent from "../../Components/AdComponent/AdComponent";
import { AdContext } from "../../Context/AdContext";
import runOneSignal from "../../OneSignalConfig";
import { MatchesContextAlt } from "../../Context/MatchesContextAlt";
import { useState } from "react";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import HomeEmailSIgnupContainer from "../HomePage/HomeEmailSIgnupContainer";
import moment from "moment/moment";

const ScorePageMatches = () => {
  // context
  const {
    allMatchesFromApi,
    loadingLeagueMatches,
    getAllLeaguesANdEventsAlt,
    isLoadingMainFunction,
    formattedDate,
  } = useContext(MatchesContext);

  const { getAllLeaguesANdEventsAltAtIntervals } =
    useContext(MatchesContextAlt);

  const { scoresPageAds } = useContext(AdContext);

  // States
  const [displayEmailSignUpModal, setDisplayEmailSignupModal] = useState(false);

  // Local
  const isSubscribed = localStorage.getItem("gnn-email-signup");

  useEffect(() => {
    if (!isLoadingMainFunction) {
      getAllLeaguesANdEventsAlt();
    }

    runOneSignal();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isSubscribed) {
      setDisplayEmailSignupModal(true);
    } else {
      setDisplayEmailSignupModal(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscribed]);

  // Utils
  const todaysDate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if (allMatchesFromApi.length > 0) {
      let allMatchData = [];

      allMatchData = allMatchesFromApi
        .map((data) => {
          return Object.values(data);
        })
        .flat();

      const allUnLiveMatch = allMatchData.filter((data) => {
        return (
          data.status_type === "notstarted" || data.status_type === "finished"
        );
      });

      let fetchMatchDetails;

      if (
        allUnLiveMatch.length !== allMatchesFromApi.length &&
        formattedDate === todaysDate
      ) {
        fetchMatchDetails = setInterval(() => {
          getAllLeaguesANdEventsAltAtIntervals();
        }, 20000);
      }

      return () => {
        clearInterval(fetchMatchDetails);
      };
    }

    // eslint-disable-next-line
  }, [allMatchesFromApi]);

  return (
    <ScorePageLayout showNavSection={true}>
      {displayEmailSignUpModal && (
        <AcceptedModal
          body={
            <HomeEmailSIgnupContainer
              onClick={() => {
                setDisplayEmailSignupModal(false);
              }}
            />
          }
          onClick={() => {
            setDisplayEmailSignupModal(false);
          }}
          style={{ maxHeight: "400px" }}
        />
      )}
      {loadingLeagueMatches && allMatchesFromApi.length < 1 ? (
        <LinearProgress
          color="inherit"
          style={{ color: "#ffd91b", height: ".1rem" }}
        />
      ) : !loadingLeagueMatches && allMatchesFromApi.length < 1 ? (
        <div className={classes.noMatches}>No matches available</div>
      ) : (
        allMatchesFromApi?.map((match, i) => {
          if ((i + 1) % 3 === 0) {
            return (
              <React.Fragment key={i}>
                <LeagueMatchContainer
                  leagueEvent={Object.values(match)}
                  key={i}
                />
                <div className={classes.adContainer}>
                  <AdComponent
                    adArray={scoresPageAds}
                    section="1024px-by-90px-between-league-matches"
                  />
                </div>
                <div className={classes.mobileAdContainer}>
                  <AdComponent
                    adArray={scoresPageAds}
                    section="1024px-by-90px-between-league-matches-(mobile)"
                  />
                </div>
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={i}>
              <LeagueMatchContainer leagueEvent={Object.values(match)} />
            </React.Fragment>
          );
        })
      )}
    </ScorePageLayout>
  );
};

export default ScorePageMatches;
