import React from "react";
import classes from "./Footer.module.css";
import goalNowNowLogo from "../../Assets/Images/gnnLogo.png";
import { v4 } from "uuid";
import { Link } from "react-router-dom";
import { navItems } from "../../Utilities/navItems";
import { scrollToTop } from "../../Utilities/scrollFunctions";

const Footer = () => {
  const footerNav = [
    { id: v4(), title: "About Us", route: "/about-us" },
    { id: v4(), title: "Advertise", route: "/advertise" },
    { id: v4(), title: "News", route: "/sport-news" },
    { id: v4(), title: "Privacy Notice", route: "/privacy-notice" },
    { id: v4(), title: "Cookie Policy", route: "/cookie-policy" },
  ];
  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.logosection}>
          <img src={goalNowNowLogo} alt="GoalNowNow Logo" />
        </div>
        <div className={classes.textSection}>
          <h6>
            Scorenownow - Latest World Cup Football Scores, Results, Fixtures
            and Tables
          </h6>
          <p>
            Your go-to destination for the latest football scores and news from
            around the world, including up-to-date live tables, fixtures and
            scores from all leagues and competitions - the Premier League, La
            Liga, Serie A, Bundesliga, Ligue 1, Champions League, and the Europa
            League. Also, get FA Cups and international tournaments (World Cups,
            Euros, AFCON, Copa America, and Nations League) at your fingertip
          </p>

          <p>&copy; 2024 Scorenownow</p>
        </div>
        <div className={classes.footerNav}>
          {footerNav.map((data) => {
            return (
              <Link to={data.route} key={data.id} onClick={scrollToTop}>
                {data.title}
              </Link>
            );
          })}
        </div>
      </div>
      <div className={classes.mobileNav}>
        {navItems.slice(0, 3).map((data) => {
          return (
            <Link
              to={data.route}
              className={
                window.location.href.includes(data.route)
                  ? `${classes.activeNav}`
                  : undefined
              }
              key={data.id}
            >
              {data.icon}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
