import React, {
  createContext,
  // useCallback,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { countries } from "../Utilities/countries";
import moment from "moment/moment";
import { v4 } from "uuid";
import { incidentTypes } from "../Utilities/incidentTypes";
import { useRef } from "react";

export const MatchesContext = createContext();

const MatchesContextProvider = (props) => {
  // state
  const [showOdds, setShowOdds] = useState(false);
  const [tournamentsTemplate, setTournamentsTemplate] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [leagueLoading, setLeagueLoading] = useState(false);
  const [countryNameFlag, setCountryNameFlag] = useState("");
  const [leagueParticipant, setLeagueParticipant] = useState([]);
  const [activeLeague, setActiveLeague] = useState([]);
  const [leagueMatches, setLeagueMatches] = useState([]);
  const [requiredDate, setRequiredDate] = useState(
    moment().format(moment.HTML5_FMT.DATE)
  );
  const [isFetchingCommentary, setIsFetchingCommentary] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [allMatchesFromApi, setAllMatchesFromApi] = useState([]);

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const [country, setCountry] = useState("");
  const [currentTime, setCurrentTime] = useState(timeZone);
  const [eventsDetails, setEventsDetails] = useState([]);
  const [eventProperties, setEventProperties] = useState([]);
  const [eventParticipants, setEventParticipants] = useState([]);
  const [matchStatistics, setMatchStatistics] = useState([]);
  const [matchDataCombinedToFit, setMatchDataCombinedToFit] = useState([]);
  const [isloadingMatchStatistics, setIsLoadingMatchStatistics] =
    useState(false);
  const [specificMatchData, setSpecificmatchData] = useState();
  const [matchCommentary, setMatchCommentary] = useState([]);
  const [displaySearchComponent, setDisplaySearchComponent] = useState(false);
  const [allCompetitions, setAllCompetitions] = useState([]);
  const [featuredMatch, setFeaturedMatch] = useState([]);
  const [eventIncidents, setEventIncidents] = useState([]);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [statusType, setStatusType] = useState(false);
  const [favouritedMatches, setFavouritedMatches] = useState([]);
  const [includeLive, setIncludeLive] = useState(false);
  const [loadingLeagueMatches, setLoadingLeagueMatches] = useState(false);

  // formatted with moment
  const formattedDate = moment(requiredDate).format(moment.HTML5_FMT.DATE);

  // get country flag
  // we are getting country flag based on a dummy array of countries and their abbreviations. this is used by the react-country-flags to get the flag of each country dynamically since the API doesnt necessarily provide for such
  let countryAbbreviation = countries?.filter((data) => {
    return data.name === countryNameFlag;
  })[0]?.code;

  // to run fetchtournamentstage everytime the present tournament id changes

  const [testLeagues, setTestLEagues] = useState();

  const includeLiveQueryParam = includeLive ? "&status_type=inprogress" : "";
  const isFirstRenderRef = useRef(true);
  const [isLoadingMainFunction, setIsLoadingMainFunction] = useState(true);

  // const test = () => {
  //   axios
  //     .get(`https://api.sportdm.com/api/v1/gettornament`)
  //     .then((res) => {
  //       console.log(res, "Test");
  //     })
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   test();
  // }, []);

  const getAllLeaguesANdEvents = async () => {
    setLeagueLoading(true);
    setLoadingLeagueMatches(true);
    setAllMatchesFromApi([]);
    setIsLoadingMainFunction(true);

    try {
      const res = await axios.get(
        `https://api.sportdm.com/api/v1/gettornament`
      );

      // Fetch league matches for all events
      const allResponses = [];

      const promises = res.data.tournament_templates?.map(async (league) => {
        return axios
          .get(
            `https://eapi.enetpulse.com/event/daily/?date=${formattedDate}${includeLiveQueryParam}&live=yes&includeVenue=yes&includeEventProperties=yes&includeCountryCodes=no&tz=${currentTime}&tournament_templateFK=${league?.tt_id}&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
          )
          .then((response) => {
            if (Object.values(response.data.events).length > 0) {
              return response.data.events;
            } else {
              return {};
            }
          })
          .catch((error) => {
            setLoadingLeagueMatches(false);
            return;
          });
      });

      try {
        const responses = await Promise.all(promises);
        // Add all the responses to the allResponses array
        allResponses.push(...responses);
        setLoadingLeagueMatches(false);
      } catch (error) {
        setLoadingLeagueMatches(false);
      }

      const filteredResponses = allResponses.filter((data) => {
        return Object.keys(data).length > 0;
      });

      // }

      setAllMatchesFromApi(filteredResponses);
      setFeaturedMatch(Object.values(filteredResponses[0]));

      const tournamentPromises = Object.values(
        res.data.tournament_templates
      ).map(async (tournamentTemplate) => {
        const tournamentRes = await axios.get(
          `https://api.sportdm.com/api/v1/tournamentPromises/${tournamentTemplate?.data?.id}`
        );

        // const currentYear = Object.values(tournamentRes.data.tournaments).find(
        //   (present) => present.name === "2022/2023" || present.name === "2023"
        // );

        const currentYear = Object.values(tournamentRes.data.tournaments)[
          Object.values(tournamentRes.data.tournaments).length - 1
        ];

        if (!currentYear) {
          // If there's no current year, skip this tournament
          return [];
        }

        const currentYearId = currentYear?.ts_id;

        const tournamentStageRes = await axios.get(
          `https://api.sportdm.com/api/v1/tournamentStageRes/${currentYearId}`
        );

        return Object.values(tournamentStageRes.data.tournament_stages);
      });

      const testLeagues = await Promise.all(tournamentPromises).then((values) =>
        values.flat()
      );

      setAllCompetitions(testLeagues);

      const leaguesByCountry = testLeagues.reduce((acc, league) => {
        const countryName = league.country_name;
        if (!acc[countryName]) {
          // acc[countryName] = [league];
          acc[countryName] = [league];
        } else {
          // acc[countryName].push(league);
          acc[countryName].push(league);
        }
        return acc;
      }, {});

      setLeagueLoading(false);

      setTestLEagues(leaguesByCountry);
      setIsLoadingMainFunction(false);
    } catch (err) {
      setLeagueLoading(false);
      setIsLoadingMainFunction(false);

      return;
    }
  };

  // useEffect(() => {
  //   setInterval(() => {
  //     test();
  //   }, 2000);
  // }, []);

  const getAllLeaguesANdEventsAlt = async () => {
    setAllMatchesFromApi([]);
    setLoadingLeagueMatches(true);

    try {
      const res = await axios.get(
        // `https://api.sportdm.com/api/v1/gettornament`
        `https://eapi.enetpulse.com/tournament_template/list/?sportFK=1&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
      );

      // Fetch league matches for all events
      const allResponses = [];

      const promises = Object.values(res.data?.tournament_templates).map(
        async (league) => {
          // const promises = res?.tournament_templates.map(async (league) => {
          return axios
            .get(
              // `https://eapi.enetpulse.com/event/daily/?date=${formattedDate}${includeLiveQueryParam}&live=yes&includeVenue=yes&includeEventProperties=yes&includeCountryCodes=no&tz=${currentTime}&tournament_templateFK=${league?.tt_id}&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
              `https://eapi.enetpulse.com/event/daily/?date=${formattedDate}${includeLiveQueryParam}&live=yes&includeVenue=yes&includeEventProperties=yes&includeCountryCodes=no&tz=${currentTime}&tournament_templateFK=${league?.id}&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
            )
            .then((response) => {
              if (Object.values(response.data.events).length > 0) {
                return response.data.events;
              } else {
                return {};
              }
            })
            .catch((error) => {
              setLoadingLeagueMatches(false);
            });
        }
      );

      try {
        const responses = await Promise.all(promises);

        allResponses.push(...responses);
        setLoadingLeagueMatches(false);
      } catch (error) {
        setLoadingLeagueMatches(false);
      }

      const filteredResponses = allResponses.filter((data) => {
        return Object.keys(data).length > 0;
      });

      // }

      setAllMatchesFromApi(filteredResponses);
      setLeagueLoading(false);
    } catch (err) {
      console.log(err, "Hmmm");

      setLeagueLoading(false);
      setIsLoadingMainFunction(false);
    }
  };

  // eslint-disable-next-line
  // }, [currentTime, formattedDate, statusType, includeLive]);

  useEffect(() => {
    getAllLeaguesANdEvents();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      getAllLeaguesANdEventsAlt();

      // getAllLeaguesANdEventsAltAtIntervals();
    } else {
      isFirstRenderRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, formattedDate, statusType, includeLive]);

  //State

  const [firstParticipantLineup, setFirstParticipantLineup] = useState([]);
  const [secondParticipantLineup, setSecondParticipantLineup] = useState([]);

  const fetchEventDetails = (id) => {
    axios
      .get(
        `https://eapi.enetpulse.com/event/details/?id=${id}&includeLineups=yes&includeEventProperties=yes&includeTeamProperties=yes&includeIncidents=yes&includeExtendedResults=yes&includeProperties=yes&includeLivestats=yes&includeVenue=yes&includeCountryCodes=yes&includeDeleted=no&includeReference=yes&includeObjectParticipants=yes&includeEventIncidentRelation=yes&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
      )
      .then((res) => {
        setEventsDetails(Object.values(res.data.event));
        setEventParticipants(
          Object.values(res.data.event[id].event_participants)
        );

        setEventProperties(
          Object.values(Object.values(res.data.event[id].property))
        );
        // Operations

        setFirstParticipantLineup(
          Object.values(
            Object.values(res.data?.event[id]?.event_participants)[0]?.lineup
          )
        );

        setSecondParticipantLineup(
          Object.values(
            Object.values(res.data?.event[id]?.event_participants)[1]?.lineup
          )
        );
      })
      .catch((err) => {
        return;
      });
  };

  // Match lineups

  // First participants results keys

  const [firstParticipantResults, setFirstParticipantResults] = useState([]);
  const [secondParticipantResults, setSecondParticipantResults] = useState([]);
  const [firstParticipantScopeResults, setFirstParticipantScopeResults] =
    useState([]);
  const [secondParticipantScopeResults, setSecondParticipantScopeResults] =
    useState([]);

  const [stadium, setStadium] = useState("");

  const getEventDescriptionType = (id) => {
    const eventDescription = incidentTypes?.find((data) => {
      return data.id === id;
    });
    return eventDescription;
  };

  const fetchSpecificMatchEvents = (id) => {
    setIsSendingRequest(true);
    setEventParticipants([]);
    setFirstParticipantResults([]);
    setSecondParticipantResults([]);
    setFirstParticipantScopeResults([]);
    setEventIncidents([]);

    let firstParticipantIncidents = [];
    let secondParticipantIncidents = [];

    axios
      .get(
        `https://eapi.enetpulse.com/event/details/?id=${id}&includeLineups=yes&includeEventProperties=yes&includeTeamProperties=yes&includeIncidents=yes&includeExtendedResults=yes&includeProperties=yes&includeLivestats=yes&includeVenue=yes&includeCountryCodes=yes&includeFirstLastName=no&includeReference=yes&includeObjectParticipants=yes&includeEventIncidentRelation=yes&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
      )
      .then((res) => {
        setSpecificmatchData(res.data.event[id]);
        setEventParticipants(
          Object.values(res.data.event[id].event_participants)
        );

        // First participants results keys
        let firstParticipantResultKeys = [];
        let secondParticipantResultKeys = [];

        // stadium
        setStadium(Object.values(res.data.event[id].venue)[0].name);

        //  results
        firstParticipantResultKeys = Object.keys(
          Object.values(res.data.event[id].event_participants)[0].result
        );

        // incidents

        for (let i = 0; i < firstParticipantResultKeys.length; i++) {
          const currentResult = Object.values(
            res.data.event[id].event_participants
          )[0]?.result[firstParticipantResultKeys[i]];
          setFirstParticipantResults((prevState) => [
            ...prevState,
            currentResult,
          ]);
        }

        firstParticipantIncidents = Object.values(
          Object.values(
            Object.values(res?.data?.event)[0]?.event_participants
          )[0]?.incident || []
        ).map((data) => {
          return { ...data, tag: "home", newFrontendId: v4() };
        });

        secondParticipantIncidents = Object.values(
          Object.values(Object.values(res.data.event)[0]?.event_participants)[1]
            ?.incident || []
        )?.map((data) => {
          return { ...data, tag: "away", newFrontendId: v4() };
        });

        setEventIncidents([
          ...firstParticipantIncidents,
          ...secondParticipantIncidents,
        ]);

        secondParticipantResultKeys = Object.keys(
          Object.values(res.data.event[id].event_participants)[1]?.result
        );

        for (let i = 0; i < secondParticipantResultKeys.length; i++) {
          const currentResult = Object.values(
            res.data.event[id].event_participants
          )[1]?.result[secondParticipantResultKeys[i]];
          setSecondParticipantResults((prevState) => [
            ...prevState,
            currentResult,
          ]);
        }

        // First participant scope results

        let firstParticipantsScopeResults = [];
        if (
          Object.values(Object.values(res.data.event)[0]?.event_participants)[0]
            .scope_result
        ) {
          firstParticipantsScopeResults = Object.values(
            Object.values(
              Object.values(res?.data?.event)[0]?.event_participants
            )[0]?.scope_result
          );
        } else {
          firstParticipantsScopeResults = [];
        }

        setFirstParticipantScopeResults(firstParticipantsScopeResults);

        // Second Particant Scope results
        let secondParticipantsScopeResults = [];
        if (
          Object.values(
            Object.values(
              Object.values(res?.data?.event)[0]?.event_participants
            )[1]?.scope_result
          )
        ) {
          secondParticipantsScopeResults = Object.values(
            Object.values(
              Object.values(res?.data?.event)[0]?.event_participants
            )[1]?.scope_result
          );
        } else {
          secondParticipantsScopeResults = [];
        }

        setSecondParticipantScopeResults(secondParticipantsScopeResults);

        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
      });
  };

  // fetch match statistics
  const fetchMatchStatistics = (id) => {
    setIsLoadingMatchStatistics(true);
    setMatchStatistics([]);
    setMatchDataCombinedToFit([]);
    axios
      .get(
        `https://eapi.enetpulse.com/standing/event_stats/?object=event&objectFK=${id}&includeStandingParticipants=yes&includeStandingConfig=yes&includeStandingData=yes&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
      )
      .then((res) => {
        setMatchStatistics([]);
        const standingsKey = Object.keys(res.data.standings);

        for (let i = 0; i < standingsKey.length; i++) {
          setMatchStatistics(
            Object.values(
              res.data.standings[standingsKey].standing_participants
            )
          );
        }
        setIsLoadingMatchStatistics(false);
      })
      .catch((err) => {
        setIsLoadingMatchStatistics(false);
      });
  };

  useEffect(() => {
    const homeTeamIndex = 0;
    const awayTeamIndex = 1;

    const codesToInclude = [
      { code: "shoton", name: "Shots on target" },
      { code: "shotoff", name: "Shots off target" },
      { code: "goal_attempt", name: "Attempts on goal" },
      { code: "blocked_shots", name: "Blocked Shots" },
      { code: "possession", name: "Possession (%)" },
      { code: "counter", name: "Counters" },
      { code: "foulcommit", name: "Fouls" },
      { code: "cross", name: "Crosses" },
      { code: "corner", name: "Corners" },
      { code: "offside", name: "Offsides" },
      { code: "goalkick", name: "Goal kicks" },
      { code: "freekick", name: "Free Kicks" },
      { code: "yellow_cards", name: "Yellow Cards" },
      { code: "red_cards", name: "Red Cards" },
      { code: "saves", name: "Saves" },
    ];

    const resultArray = codesToInclude.map((code) => {
      const homeTeamValue = matchStatistics[homeTeamIndex]?.standing_data?.find(
        (data) => data.code === code.code
      )?.value;
      const awayTeamValue = matchStatistics[awayTeamIndex]?.standing_data?.find(
        (data) => data.code === code.code
      )?.value;

      return {
        code: code.code,
        homeValue: homeTeamValue,
        awayValue: awayTeamValue,
        name: code.name,
      };
    });
    setMatchDataCombinedToFit(resultArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchStatistics]);

  // get match commetary
  const fetchMatchCommentary = (id) => {
    setIsFetchingCommentary(true);
    setMatchCommentary([]);
    axios
      .get(
        `https://eapi.enetpulse.com/event/commentaries/?id=${id}&limit=100&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
      )
      .then((res) => {
        setMatchCommentary(Object.values(res.data.event)[0].event_incident);
        setIsFetchingCommentary(false);
      })
      .catch((err) => {
        setIsFetchingCommentary(false);
      });
  };

  // Comment
  // because we are now grting to get the id of each current season (which should always be an array of one element based on the filter we did)

  const fetchLeagueMatchesDataAndEvents = (id) => {
    axios
      .get(
        `http://eapi.enetpulse.com/event/list/?date=2023-01-19&live=no&includeVenue=yes&status_type=notstarted&includeEventProperties=yes&includeCountryCodes=no&includeFirstLastName=yes&includeDeleted=yes&tf=Y-m-dH:i:s&tz=Africa/Accra&tournament_stageFK=17664&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
      )
      .then((res) => {
        setLeagueMatches(
          Object.values(res.data.events)
            .map((data) => {
              return { ...data, isFavorited: false };
            })
            .filter((data) => {
              return data.startdate.slice(0, 10) === formattedDate;
            })
        );
      })
      .catch((err) => {
        return;
      });
  };

  return (
    <MatchesContext.Provider
      value={{
        showOdds,
        setShowOdds,
        tournamentsTemplate,
        setTournamentsTemplate,
        tournaments,
        setTournaments,
        leagues,
        setLeagues,
        leagueLoading,
        setCountryNameFlag,
        countryAbbreviation,
        leagueParticipant,
        setLeagueParticipant,
        activeLeague,
        setActiveLeague,
        fetchLeagueMatchesDataAndEvents,
        requiredDate,
        setRequiredDate,
        leagueMatches,
        setLeagueMatches,
        country,
        setCountry,
        currentTime,
        setCurrentTime,
        fetchEventDetails,
        eventsDetails,
        eventProperties,
        eventParticipants,
        firstParticipantLineup,
        secondParticipantLineup,
        fetchMatchStatistics,
        matchDataCombinedToFit,
        isloadingMatchStatistics,
        fetchSpecificMatchEvents,
        specificMatchData,
        isSendingRequest,
        firstParticipantResults,
        secondParticipantResults,
        eventIncidents,
        setEventIncidents,
        stadium,
        getEventDescriptionType,
        formattedDate,
        setSpecificmatchData,
        setEventParticipants,
        setStadium,
        setFirstParticipantResults,
        setSecondParticipantResults,
        firstParticipantScopeResults,
        secondParticipantScopeResults,
        setFirstParticipantScopeResults,
        setSecondParticipantScopeResults,
        setIsSendingRequest,
        statusType,
        setStatusType,
        fetchMatchCommentary,
        matchCommentary,
        favouritedMatches,
        setFavouritedMatches,
        includeLive,
        setIncludeLive,
        isFetchingCommentary,
        setMatchCommentary,
        testLeagues,
        searchTerm,
        setSearchTerm,
        displaySearchComponent,
        setDisplaySearchComponent,
        allCompetitions,
        setAllCompetitions,
        allMatchesFromApi,
        setLoadingLeagueMatches,
        loadingLeagueMatches,
        getAllLeaguesANdEventsAlt,
        getAllLeaguesANdEvents,
        isFirstRenderRef,
        isLoadingMainFunction,
        setAllMatchesFromApi,
        featuredMatch,
      }}
    >
      {props.children}
    </MatchesContext.Provider>
  );
};

export default MatchesContextProvider;
