import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import { MatchesContext } from "./MatchesContext";

export const TablesContext = createContext();

const TablesContextProvider = (props) => {
  // context
  const { currentTime } = useContext(MatchesContext); // eslint-disable-line

  // states
  const [premierLeagueTable, setPremierLeagueTable] = useState([]);
  const [spanishLeagueTable, setSpanishLeagueTable] = useState([]);
  const [frenchLeagueTable, setFrenchLeagueTable] = useState([]);
  const [germanLeagueTable, setGermanLeagueTable] = useState([]);
  const [italianLeagueTable, setItalianLeagueTable] = useState([]);
  const [allLeagueTeables, setAllLeagueTables] = useState([]);
  const [allLeagueTeablesLoading, setAllLeagueTablesLoading] = useState(false);

  // $url = env('enetpulse_url_api') . "/tournament_stage/list/?tournamentFK={$currentYearId}&username=" . env('ENETPULSE_USERNAME') . "&token=" . env('ENETPULSE_TOKEN_ID');

  const getAllLeagueTables = async () => {
    setAllLeagueTablesLoading(true);

    try {
      const res = await axios.get(
        `https://api.sportdm.com/api/v1/gettornament`
      );

      // Fetch league matches for all events

      const tournamentPromises = res.data.tournament_templates
        // .slice(0, 10)
        .map(async (tournamentTemplate) => {
          const tournamentRes = await axios.get(
            `https://api.sportdm.com/api/v1/tournamentPromises/${tournamentTemplate?.data?.id}`
          );

          const currentYear = Object.values(
            tournamentRes.data.tournaments
          ).find(
            (present) => present.name === "2023/2024" || present.name === "2024"
          );
          if (!currentYear) {
            return [];
          }
          const currentYearId = currentYear?.ts_id;

          const tournamentStageRes = await axios.get(
            `https://api.sportdm.com/api/v1/tournamentStageRes/${currentYearId}`
          );

          return Promise.all(
            tournamentStageRes.data.tournament_stages.map(
              async (tournamentState) => {
                const tournamentLeaguesTablesRes = await axios.get(
                  `https://eapi.enetpulse.com/standing/liveleaguetable/?object=tournament_stage&objectFK=${tournamentState?.tss_id}&limit=20&offset=0&includeStandingParticipants=yes&includeStandingParticipantsProperties=yes&includeStandingData=yes&includeCountryCodes=no&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}`
                );

                let leagueTableDetails = {};

                if (
                  Object.values(tournamentLeaguesTablesRes.data.standings)
                    .length > 0
                ) {
                  leagueTableDetails =
                    tournamentLeaguesTablesRes.data.standings;
                  leagueTableDetails["league_name"] = tournamentState.name;
                  leagueTableDetails["country_name"] =
                    tournamentState.country_name;

                  return leagueTableDetails;
                } else {
                  return {};
                }
              }
            )
          );
        });

      const leagueTables = await Promise.all(tournamentPromises).then(
        (values) => values.flat()
      );

      const filterLeagueTables = leagueTables.filter(
        (data) => Object.keys(data).length > 0
      );

      const returnLeagueTable = (id, setState) => {
        const leagueObject = filterLeagueTables.find((data) => {
          if (data) {
            return data[id];
          }
          return null;
        })[id].standing_participants;

        setState(Object.values(leagueObject));
      };

      returnLeagueTable(434317, setPremierLeagueTable);
      returnLeagueTable(436634, setSpanishLeagueTable);
      returnLeagueTable(435950, setItalianLeagueTable);
      returnLeagueTable(435272, setFrenchLeagueTable);
      returnLeagueTable(435844, setGermanLeagueTable);

      setAllLeagueTables(filterLeagueTables);
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    const getTableTimeout = setTimeout(() => {
      getAllLeagueTables();
    }, 3000);

    return () => {
      clearTimeout(getTableTimeout);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <TablesContext.Provider
      value={{
        premierLeagueTable,
        spanishLeagueTable,
        frenchLeagueTable,
        germanLeagueTable,
        italianLeagueTable,
        allLeagueTeables,
        getAllLeagueTables,
        allLeagueTeablesLoading,
      }}
    >
      {props.children}
    </TablesContext.Provider>
  );
};

export default TablesContextProvider;
