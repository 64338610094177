import React, { useContext } from "react";
import BreakingNews from "./BreakingNews";
import FeaturedMatch from "./FeaturedMatch";
import Headlines from "./Headlines";
import LiveTables from "./LiveTables";
import classes from "./NewsAndLiveMatches.module.css";
import { NewsContext } from "../../Context/NewsContext";
import { CircularProgress } from "@mui/material";
import AdComponent from "../../Components/AdComponent/AdComponent";
import { AdContext } from "../../Context/AdContext";

const NewsAndLiveMatches = () => {
  // Context
  const { isFetchingFeaturedNews } = useContext(NewsContext);
  const { scoresPageAds } = useContext(AdContext);

  return (
    <div className={classes.container}>
      {isFetchingFeaturedNews ? (
        <div className={classes.loading}>
          <CircularProgress
            color="inherit"
            style={{ color: "#ffd91b" }}
            size="1rem"
          />
        </div>
      ) : (
        <>
          <div className={classes.breaking}>
            <BreakingNews />
          </div>
          <div className={classes.headlines}>
            <Headlines />
          </div>
        </>
      )}
      <div className={classes.adSection}>
        <AdComponent
          adArray={scoresPageAds}
          section="300px-by-250px-between-the-story-highlights-and-the-featured-matches"
        />
      </div>

      <div className={classes.featuresMatches}>
        <FeaturedMatch />
      </div>
      <div className={classes.liveTable}>
        <LiveTables />
      </div>
      <div className={classes.adSection}>
        {/* <AdComponent
          adArray={scoresPageAds}
          section="300px-by-250px-between-the-story-highlights-and-the-featured-matches"
        /> */}
      </div>
    </div>
  );
};

export default NewsAndLiveMatches;
