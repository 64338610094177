import Layout from "../../Components/Layout/Layout";
import classes from "./AboutUs.module.css";
import { aboutUs } from "../../Utilities/aboutUsAndTerms";

const AboutUs = () => {
  return (
    <Layout notDisplaySide>
      <div className={classes.container}>
        <h4>SCORENOWNOW</h4>
        <p>{aboutUs}</p>
      </div>
    </Layout>
  );
};

export default AboutUs;
