import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
export const NewsContext = createContext();

const NewsContextProvider = (props) => {
  // States
  const [featuresNews, setFeaturedNews] = useState([]);
  const [isFetchingFeaturedNews, setIsFetchingFeaturedNews] = useState(false);
  const [offsetValue, setOffsetValue] = useState(0);
  const [teamSpecificNews, setTeamSpecificNews] = useState([]);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [index, setIndex] = useState(0);
  const [headline, setHeadlines] = useState([]);
  const [headlineOffSetValue, setHeadlineOffsetValue] = useState(0);
  const [email, setEmail] = useState("");
  const [emailSubscribeObject, setEmailSUbscribeObject] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  // Utilities
  if (offsetValue > 100) {
    setOffsetValue(0);
  }

  if (headline > 100) {
    setHeadlineOffsetValue(0);
  }

  // API Calls
  const fetchFeaturedNews = () => {
    setIsFetchingFeaturedNews(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNews?type=all-headlines`
      )
      .then((res) => {
        setFeaturedNews(
          res.data.data.map((data, i) => {
            if (i === 1) {
              return { ...data, isActive: true };
            } else return { ...data, isActive: false };
          })
        );
        setIsFetchingFeaturedNews(false);
      })
      .catch((err) => {
        console.log(err);
        setIsFetchingFeaturedNews(false);
      });
  };

  // State

  // Set active News

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex(index + 1);
      if (index === 2) {
        setIndex(0);
      }
      const newsCopy = [...featuresNews].map((data, i) => {
        if (i === index) {
          return {
            ...data,
            isActive: true,
          };
        }
        return {
          ...data,
          isActive: false,
        };
      });
      setFeaturedNews(newsCopy);
    }, 5000);

    return () => {
      clearInterval(timer);
    };

    // eslint-disable-next-line
  }, [featuresNews]);

  const fetchTeamSpecificNews = (team) => {
    setTeamSpecificNews([]);
    setIsSendingRequest(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNewsByTag?tag=${team
          .toLowerCase()
          .replace(/\s/g, "-")}`
      )
      .then((res) => {
        setIsSendingRequest(false);

        setTeamSpecificNews(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setIsSendingRequest(false);
      });
  };

  // Effects
  useEffect(() => {
    fetchFeaturedNews();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllHeadlines = () => {
    setIsSendingRequest(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNews?type=football-news`
      )
      .then((res) => {
        setHeadlines(res.data.data);
        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
      });
  };

  const subsscribeToNewsLetter = () => {
    setEmailSUbscribeObject({
      isLoading: true,
      data: null,
      error: null,
    });
    axios
      .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/subscribe`, {
        email,
      })
      .then((res) => {
        console.log(res);
        localStorage.setItem("gnn-email-signup", "true");
        setEmailSUbscribeObject({
          isLoading: false,
          data: res?.data?.message,
          error: null,
        });
        setEmail("");
      })
      .catch((err) => {
        console.log(err);

        setEmailSUbscribeObject({
          isLoading: false,
          data: null,
          error: err?.response?.data?.message,
        });
      });
  };

  useEffect(() => {
    fetchAllHeadlines();

    // eslint-disable-next-line
  }, [headlineOffSetValue]);

  return (
    <NewsContext.Provider
      value={{
        fetchFeaturedNews,
        isFetchingFeaturedNews,
        featuresNews,
        fetchTeamSpecificNews,
        setOffsetValue,
        teamSpecificNews,
        isSendingRequest,
        offsetValue,
        setFeaturedNews,
        fetchAllHeadlines,
        headline,
        setHeadlineOffsetValue,
        email,
        setEmail,
        subsscribeToNewsLetter,
        emailSubscribeObject,
      }}
    >
      {props.children}
    </NewsContext.Provider>
  );
};

export default NewsContextProvider;
