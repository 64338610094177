export const incidentTypes = [
  {
    id: "7",
    name: "Regular goal",
    subtype: "goal",
    n: "1",
    ut: "2019-03-05T06:46:08+00:00",
  },
  {
    id: "8",
    name: "Penalty",
    subtype: "goal",
    n: "1",
    ut: "2022-07-15T13:00:58+00:00",
  },
  {
    id: "9",
    name: "Missed penalty",
    subtype: "goal",
    n: "1",
    ut: "2022-07-15T13:00:58+00:00",
  },
  {
    id: "10",
    name: "Own goal",
    subtype: "goal",
    n: "1",
    ut: "2022-07-15T13:00:58+00:00",
  },
  {
    id: "11",
    name: "Penalty shootout missed",
    subtype: "goal",
    n: "1",
    ut: "2019-10-30T10:41:38+00:00",
  },
  {
    id: "12",
    name: "Penalty shootout scored",
    subtype: "goal",
    n: "1",
    ut: "2022-07-15T13:00:58+00:00",
  },
  {
    id: "14",
    name: "Yellow card",
    subtype: "card",
    n: "0",
    ut: "2019-03-05T06:46:12+00:00",
  },
  {
    id: "15",
    name: "Yellow card 2",
    subtype: "card",
    n: "0",
    ut: "2019-03-05T06:46:13+00:00",
  },
  {
    id: "16",
    name: "Red card",
    subtype: "card",
    n: "0",
    ut: "2017-01-03T08:27:40+00:00",
  },
  {
    id: "18",
    name: "Extratime Goal",
    subtype: "goal",
    n: "0",
    ut: "2019-03-05T06:46:14+00:00",
  },
  {
    id: "19",
    name: "Extratime missed penalty",
    subtype: "goal",
    n: "0",
    ut: "2019-03-05T06:46:15+00:00",
  },
  {
    id: "20",
    name: "Substitution out",
    subtype: "subst",
    n: "0",
    ut: "2019-03-05T06:46:16+00:00",
  },
  {
    id: "21",
    name: "Power Play goal",
    subtype: "goal",
    n: "0",
    ut: "2017-01-12T16:34:47+00:00",
  },
  {
    id: "22",
    name: "Short Handed goal",
    subtype: "goal",
    n: "0",
    ut: "2018-09-21T05:53:31+00:00",
  },
  {
    id: "23",
    name: "2 min suspension",
    subtype: "card",
    n: "0",
    ut: "2017-01-12T16:35:21+00:00",
  },
  {
    id: "24",
    name: "5 min suspension",
    subtype: "card",
    n: "0",
    ut: "2017-01-12T16:35:38+00:00",
  },
  {
    id: "25",
    name: "10 min suspension",
    subtype: "card",
    n: "0",
    ut: "2017-01-19T13:02:40+00:00",
  },
  {
    id: "26",
    name: "Match Penalty",
    subtype: "card",
    n: "0",
    ut: "2019-03-05T06:46:17+00:00",
  },
  {
    id: "27",
    name: "Game Misconduct",
    subtype: "card",
    n: "0",
    ut: "2018-09-21T05:53:31+00:00",
  },
  {
    id: "28",
    name: "Extratime penalty scored",
    subtype: "goal",
    n: "0",
    ut: "2022-07-15T13:00:58+00:00",
  },
  {
    id: "29",
    name: "Disqualification",
    subtype: "card",
    n: "0",
    ut: "2017-01-19T21:35:38+00:00",
  },
  {
    id: "30",
    name: "Exclusion",
    subtype: "card",
    n: "0",
    ut: "2017-01-19T21:08:32+00:00",
  },
  {
    id: "31",
    name: "Scoring statistics",
    subtype: "goal",
    n: "0",
    ut: "2018-09-21T05:53:31+00:00",
  },
  {
    id: "32",
    name: "Substitution in",
    subtype: "subst_in",
    n: "0",
    ut: "2022-07-15T13:00:58+00:00",
  },
  {
    id: "33",
    name: "Cancelled Goal",
    subtype: "goal",
    n: "0",
    ut: "2019-03-05T06:46:20+00:00",
  },
  {
    id: "34",
    name: "Assist",
    subtype: "assist",
    n: "0",
    ut: "2017-01-12T16:37:23+00:00",
  },
  {
    id: "35",
    name: "Assist 2nd",
    subtype: "assist",
    n: "0",
    ut: "2019-03-05T06:46:21+00:00",
  },
  {
    id: "36",
    name: "2 Point Conversion",
    subtype: "goal",
    n: "0",
    ut: "2010-02-18T15:15:42+00:00",
  },
  {
    id: "37",
    name: "Field goal",
    subtype: "goal",
    n: "0",
    ut: "2017-01-12T16:24:26+00:00",
  },
  {
    id: "38",
    name: "Safety",
    subtype: "goal",
    n: "0",
    ut: "2017-01-09T08:30:23+00:00",
  },
  {
    id: "39",
    name: "Touchdown",
    subtype: "goal",
    n: "0",
    ut: "2017-01-03T08:28:10+00:00",
  },
  {
    id: "40",
    name: "Extra Point",
    subtype: "goal",
    n: "0",
    ut: "2017-01-12T16:37:45+00:00",
  },
  {
    id: "41",
    name: "Extratime own goal",
    subtype: "goal",
    n: "0",
    ut: "2019-03-05T06:46:22+00:00",
  },
  {
    id: "42",
    name: "Try",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:55:20+00:00",
  },
  {
    id: "43",
    name: "Conversion",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:55:30+00:00",
  },
  {
    id: "44",
    name: "Dropkick",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:55:40+00:00",
  },
  {
    id: "45",
    name: "Penalty Try",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:55:50+00:00",
  },
  {
    id: "46",
    name: "Penalty Try Conversion",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:56:00+00:00",
  },
  {
    id: "47",
    name: "20 min suspension",
    subtype: "card",
    n: "0",
    ut: "2019-01-30T13:56:10+00:00",
  },
  {
    id: "48",
    name: "Golden Point Try",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:56:20+00:00",
  },
  {
    id: "49",
    name: "Golden Point Penalty",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:56:30+00:00",
  },
  {
    id: "50",
    name: "Golden Point Dropkick",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:56:40+00:00",
  },
  {
    id: "51",
    name: "25 min suspension",
    subtype: "card",
    n: "0",
    ut: "2019-01-30T13:56:50+00:00",
  },
  {
    id: "52",
    name: "2 min Bench suspension",
    subtype: "card",
    n: "0",
    ut: "2019-01-30T13:57:00+00:00",
  },
  {
    id: "53",
    name: "Single Point",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:57:10+00:00",
  },
  {
    id: "54",
    name: "Extra-time Try",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:57:20+00:00",
  },
  {
    id: "55",
    name: "Extra-time Penalty",
    subtype: "goal",
    n: "0",
    ut: "2019-03-05T06:46:23+00:00",
  },
  {
    id: "56",
    name: "Extra-time Conversion",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:57:40+00:00",
  },
  {
    id: "57",
    name: "Extra-time Penalty Try",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:57:50+00:00",
  },
  {
    id: "58",
    name: "Extra-time Dropkick",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:58:00+00:00",
  },
  {
    id: "59",
    name: "Defensive 2 Point Conversion",
    subtype: "goal",
    n: "0",
    ut: "2019-01-30T13:58:10+00:00",
  },
  {
    id: "60",
    name: "Cancelled Card",
    subtype: "card",
    n: "0",
    ut: "2019-08-05T07:53:36+00:00",
  },
  {
    id: "61",
    name: "Cancelled missed penalty",
    subtype: "goal",
    n: "0",
    ut: "2019-08-05T07:53:36+00:00",
  },
  {
    id: "62",
    name: "Cancelled penalty",
    subtype: "goal",
    n: "0",
    ut: "2019-08-05T07:53:36+00:00",
  },
  {
    id: "63",
    name: "Two Point Field Goal",
    subtype: "goal",
    n: "0",
    ut: "2021-05-11T06:56:25+00:00",
  },
  {
    id: "64",
    name: "Behind",
    subtype: "goal",
    n: "0",
    ut: "2021-07-23T11:57:49+00:00",
  },
  {
    id: "65",
    name: "Behind Rushed",
    subtype: "goal",
    n: "0",
    ut: "2021-07-23T11:58:01+00:00",
  },
  {
    id: "66",
    name: "Extratime Behind",
    subtype: "goal",
    n: "0",
    ut: "2021-08-12T11:39:06+00:00",
  },
  {
    id: "67",
    name: "Extratime Behind Rushed",
    subtype: "goal",
    n: "0",
    ut: "2021-08-12T11:39:11+00:00",
  },
  {
    id: "68",
    name: "Missed Conversion",
    subtype: "goal",
    n: "0",
    ut: "2023-01-09T15:39:45+00:00",
  },
  {
    id: "69",
    name: "Extra-time Missed Conversion",
    subtype: "goal",
    n: "0",
    ut: "2023-01-09T15:40:44+00:00",
  },
];
