export const aboutUs =
  "Powered by Native Sport, Scorenownow delivers live scores from every corner of the globe. Native Sport is a dynamic sports media company dedicated to reshaping the narrative of sports coverage. Established in 2023, our commitment is to deliver unparalleled sports content and experiences. Native Sport owns a spectrum of platforms catering to the diverse interests of sports enthusiasts. From live score updates on our dedicated website (scorenownow.com) to in-depth sports articles and engaging discussions on our podcast, we provide a holistic sports media experience.";

export const privacyNotice = [
  {
    header: "Information We Collect",
    paragraph: null,
    children: [
      {
        description:
          "Personal Information: We collect personal information such as your name, email address, and location data when you",
        list: [
          "subscribe to our newsletter or podcast",
          "create an account on our website",
          "participate in contests or surveys",
          "interact with our social media channels",
          "contact us for support",
        ],
      },
      {
        description:
          "Non-Personal Information: We may also collect non-personal information about your use of our platforms, such as:",
        list: [
          "website browsing history",
          "Website analytics",
          "information about your device and internet connection",
        ],
      },
    ],
  },

  {
    header: "How We Use Your Information:",
    paragraph: null,
    children: [
      {
        description: "We use your personal information to:",
        list: [
          "provide you with the services you request, such as score updates, news articles, and podcast episodes",
          "personalize your experience by tailoring content and recommendations",
          "send you marketing communications, with your consent",
          "analyze website traffic and user behavior to improve our services",
          "comply with legal obligations and protect our rights",
        ],
      },
    ],
  },

  {
    header: "How We Share Your Information:",
    paragraph: null,
    children: [
      {
        description: "We may share your personal information with:",
        list: [
          "Service providers: We may share your information with trusted service providers who help us operate our platforms and fulfill your requests.",
          "Legal requirements: We may disclose your information if required by law, such as in response to a court order or subpoena.",
          "Other disclosures: We may also disclose your information with your consent or in other limited circumstances, such as to protect the safety of others or to prevent fraud.",
        ],
      },
    ],
  },

  {
    header: "Your Choices:",
    paragraph: null,
    children: [
      {
        description:
          "You have the following choices regarding your personal information:",
        list: [
          'Opt-out of marketing communications: You can unsubscribe from our marketing emails at any time by clicking the "unsubscribe" link in the email.',
          "Access and update your information: You can access and update your personal information by contacting us.",
          "Delete your information: You can request that we delete your personal information by contacting us.",
        ],
      },
    ],
  },

  {
    header: "Data Retention:",
    paragraph:
      "We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required or permitted by law.",
    children: null,
  },
  {
    header: "Security:",
    paragraph:
      "We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure, and we cannot guarantee the security of your information.",
    children: null,
  },
  {
    header: "Children's Privacy:",
    paragraph:
      "Our platforms are not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and you learn that your child has provided us with personal information, please contact us.",
    children: null,
  },
  {
    header: "Changes to this Privacy Notice:",
    paragraph:
      "We may update this Privacy Notice from time to time. We will post any changes on this page. We encourage you to review this Privacy Notice regularly for any changes.",
    children: null,
  },
];

export const cookiePolicy = [
  {
    header: "What are cookies?",
    paragraph:
      "Cookies are small text files placed on your device (computer, phone, tablet) when you visit a website. They store information about your preferences, allowing websites to recognize you and enhance your browsing experience.",
    children: null,
  },

  {
    header: "What types of cookies do we use",
    paragraph: null,
    children: [
      {
        description:
          "We use the following cookies in order to serve you better:",
        list: [
          "Essential Cookies: These cookies are necessary for the website to function pro perly and cannot be disabled. They enable basic functionalities like user navigation and secure access.",
          "Performance Cookies: These cookies collect anonymous information about how you use our website, such as the pages you visit most often and the duration of your visit. This information helps us improve the website's performance and user experience.",
          "Functionality Cookies: These cookies remember your preferences, such as your language selection, to personalize your experience.",
          "Targeting Cookies: These cookies track your browsing activity across different websites and platforms to deliver targeted advertising based on your interests.",
          'Social website cookies. So you can easily "Like" or share our content, we have included sharing buttons on our site for certain social media sites such as Facebook and Twitter. These cookies are set by the third-party social media sites to which they relate, including: Facebook, Twitter, Instagram',
        ],
      },
    ],
  },

  {
    header: "Do we use third-party cookies?",
    paragraph:
      "Yes, we use third-party cookies from trusted partners like Google Analytics and advertising platforms, to analyze website traffic and deliver relevant advertisements. These cookies may collect information such as your IP address, browser type, and the pages you visit on our website.",
    children: null,
  },

  {
    header: "How can I control cookies?",
    paragraph:
      "You can manage your cookie preferences through your browser settings. Most browsers allow you to accept, reject, or delete cookies. However, disabling cookies may affect your experience on our website.",
    children: null,
  },

  {
    header: "Changes to this Policy",
    paragraph:
      "We may update this Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page.",
    children: null,
  },
];

export const advertise = [
  {
    header: "Advertise",
    paragraph:
      "Native Sport owns a spectrum of platforms catering to the diverse interests of sports enthusiasts. From live score updates on our website www.scorenownow.com to in-depth sports articles on www.sportdm.com and engaging discussions on our podcast, we provide a holistic sports media experience. ",
    children: [
      {
        description: "What makes us unique?",
        list: [
          "ScoreNowNow: Live score updates and fixtures for all major sports, right at your fingertips.",
          "SportDM: In-depth articles, features, and interviews that delve deeper into the game.",
          "Podcast: Engaging discussions, player insights, and expert analysis that fuels passionate conversations.",
          "Targeted Reach: Our diverse platforms attract a broad spectrum of sports fans, ensuring your message reaches the right audience.",
          "Engaged Community: We foster a vibrant community where fans connect, share, and debate. Be a part of the conversation and build lasting relationships with your target market.",
          "Creative Solutions: We offer various ad formats and innovative solutions to showcase your brand and resonate with our audience.",
        ],
      },
    ],
  },
];
