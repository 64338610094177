import React, { useContext, useEffect } from "react";
import Layout from "../../Components/Layout/Layout";
import { NewsContext } from "../../Context/NewsContext";
import classes from "./NewsContainer.module.css";
import { Skeleton } from "@mui/material";
import { CircularProgress } from "@mui/material";

const NewsContainer = () => {
  // Context
  const {
    fetchAllHeadlines,
    headline,
    isSendingRequest,
    setHeadlineOffsetValue,
  } = useContext(NewsContext);

  //   Effects
  useEffect(() => {
    fetchAllHeadlines();

    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <div className={classes.container}>
        <div className={classes.newsContainer}>
          {isSendingRequest && headline.length < 1 ? (
            <div className={classes.loadingContainer}>
              <CircularProgress
                color="inherit"
                style={{ color: "#ffd91b" }}
                size="1rem"
              />

              <div className={classes.adSection}>
                <Skeleton variant="rectangular" width={"100%"} height={131} />
              </div>
            </div>
          ) : !isSendingRequest && headline?.length < 1 ? (
            <div className={classes.loadingContainer}>
              No news content at this time
              <div className={classes.adSection}>
                <Skeleton variant="rectangular" width={"100%"} height={131} />
              </div>
            </div>
          ) : (
            <div className={classes.container}>
              {headline?.map((datum) => {
                let data = datum;

                const headlineImage = data?.picture;

                let betterSubjectArray;
                if (typeof data?.subject === "string") {
                  betterSubjectArray = JSON.parse(data?.subject);
                } else {
                  betterSubjectArray = data?.subject;
                }

                return (
                  <div
                    className={classes.news}
                    key={data?.uri}
                    onClick={() => {
                      window.open(`https://sportdm.com/home/${data.uri}`);
                    }}
                  >
                    <div>
                      <img src={`${headlineImage}`} alt="" />
                    </div>
                    <div className={classes.newsTextCenter}>
                      <div>{betterSubjectArray[1]?.name}</div>
                      <div>{data?.headline}</div>
                      <div>{data?.description_text}</div>
                    </div>
                  </div>
                );
              })}
              <div
                className={classes.readMore}
                onClick={() => {
                  setHeadlineOffsetValue((prevState) => prevState + 10);
                }}
              >
                {isSendingRequest ? (
                  <div>
                    {" "}
                    <CircularProgress
                      color="inherit"
                      style={{ color: "#ffd91b" }}
                      size="1rem"
                    />
                  </div>
                ) : (
                  "Read more"
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default NewsContainer;
