import React, { useContext } from "react";
import classes from "./FeaturedMatch.module.css";
import TeamLogo from "../../Components/TeamLogo/TeamLogo";
import { useNavigate } from "react-router-dom";
import { MatchesContext } from "../../Context/MatchesContext";

const FeaturedMatch = () => {
  // Context

  const { featuredMatch } = useContext(MatchesContext);

  // Navigte
  const navigate = useNavigate();

  return (
    <>
      {featuredMatch.length > 0 && (
        <div
          className={classes.container}
          onClick={() => {
            navigate(`/scores/${featuredMatch[0]?.id}/summary`);
          }}
        >
          <div className={classes.header}>Featured Match</div>
          <div className={classes.scoreSection}>
            <div>
              {featuredMatch.length > 0 && (
                <div>
                  <TeamLogo
                    id={
                      Object.values(featuredMatch[0]?.event_participants)[0]
                        ?.participantFK
                    }
                  />
                </div>
              )}

              <span>{featuredMatch[0]?.name?.split("-")[0]}</span>
            </div>
            <div>
              <div>{featuredMatch[0]?.tournament_stage_name}</div>
              <div>
                {featuredMatch.length > 0 && (
                  <>
                    {" "}
                    <div>{`${
                      Object.values(
                        Object.values(featuredMatch[0]?.event_participants)[0]
                          ?.result
                      )[1]?.value || "-"
                    } : ${
                      Object.values(
                        Object.values(featuredMatch[0]?.event_participants)[1]
                          ?.result
                      )[1]?.value || "-"
                    }`}</div>
                    {/* {firstParticipantScopeResults.length > 0 &&
                      secondParticipantScopeResults.length > 0 && (
                        <div
                          className={classes.aggregateScore}
                        >{`Aggregate (${firstParticipantScopeResults[0]?.value} : ${secondParticipantScopeResults[0]?.value})`}</div>
                      )} */}
                  </>
                )}
              </div>
              <div>
                {featuredMatch[0]?.status_type === "inprogress" && (
                  <span className={classes.liveIcon}></span>
                )}
                {featuredMatch.length > 0 && (
                  <span className={classes.matchStatus}>
                    {featuredMatch[0]?.status_type && (
                      <div>
                        {featuredMatch[0]?.status_type === "finished" && "FT"}
                      </div>
                    )}
                    {featuredMatch[0]?.status_type === "inprogress" ? (
                      <div>
                        {/* {`${Object.values(specificMatchData?.elapsed)[0]?.elapsed}'`} */}

                        {`${
                          Object.values(featuredMatch[0]?.elapsed)[0]?.elapsed
                        }${
                          Object.values(featuredMatch[0]?.elapsed)[0]
                            ?.injury_time_elapsed > 0
                            ? `+${
                                Object.values(featuredMatch[0]?.elapsed)[0]
                                  ?.injury_time_elapsed
                              }`
                            : ""
                        }'`}
                      </div>
                    ) : (
                      <div className={classes.matchTime}>
                        {featuredMatch[0]?.startdate.slice(11, 16)}
                      </div>
                    )}
                  </span>
                )}
              </div>
            </div>
            <div>
              {featuredMatch.length > 0 && (
                <div>
                  <TeamLogo
                    id={
                      Object.values(featuredMatch[0]?.event_participants)[1]
                        ?.participantFK
                    }
                  />
                </div>
              )}
              <span>{featuredMatch[0]?.name?.split("-")[1]}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeaturedMatch;
