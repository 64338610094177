import { v4 } from "uuid";

export const matches = [
  {
    id: v4(),
    leagueTitle: "La Liga",
    leagueAbbv: "ES",
    country: "Spain",
    leagueMatches: [
      {
        id: "4060624" || v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
        events: [
          { goalsBy: "A. rebic", gialTime: "14" },
          { goalsBy: "L. Messi", gialTime: "17" },
        ],
        statistics: [
          { id: v4(), title: "Shots on target", home: 9, away: 2 },
          { id: v4(), title: "Possession", home: 9, away: 2 },
          { id: v4(), title: "Passes", home: 10, away: 3 },
          { id: v4(), title: "Pass Accuracy", home: 0, away: 1 },
          { id: v4(), title: "Fouls", home: 1, away: 0 },
          { id: v4(), title: "Corners", home: 9, away: 1 },
          { id: v4(), title: "Offsides", home: 9, away: 2 },
          { id: v4(), title: "Yellow Cards", home: 15, away: 12 },
          { id: v4(), title: "Red Cards", home: 4, away: 4 },
        ],
        homeLineUp: [
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
        ],
        awayLineUp: [
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
          { id: v4(), playerName: "D. Loveren" },
        ],

        homeSubs: [
          { id: v4(), playerIn: "D. Loveren", playerOut: "Boy" },
          { id: v4(), playerIn: "D. Loveren", playerOut: "Boy" },
          { id: v4(), playerIn: "D. Loveren", playerOut: "Boy" },
          { id: v4(), playerIn: "D. Loveren", playerOut: "Boy" },
          { id: v4(), playerIn: "D. Loveren", playerOut: "Boy" },
        ],
        awaySubs: [
          { id: v4(), playerIn: "D. Loveren", playerOut: "Boy" },
          { id: v4(), playerIn: "D. Loveren", playerOut: "Boy" },
          { id: v4(), playerIn: "D. Loveren", playerOut: "Boy" },
          { id: v4(), playerIn: "D. Loveren", playerOut: "Boy" },
          { id: v4(), playerIn: "D. Loveren", playerOut: "Boy" },
        ],
        additionalInfo: [
          { id: v4(), title: "Assistant Referee", info: "D. Joe Beard" },
          { id: v4(), title: "Assistant Referee", info: "D. Joe Beard" },
          { id: v4(), title: "Assistant Referee", info: "D. Joe Beard" },
          { id: v4(), title: "Assistant Referee", info: "D. Joe Beard" },
          { id: v4(), title: "Assistant Referee", info: "D. Joe Beard" },
        ],

        summary: [
          {
            id: v4(),
            event: "Goal",
            type: "Home",
            by: "Lazo Antionio",
            time: "11",
          },
          {
            id: v4(),
            event: "Red Card",
            type: "Home",
            by: "Lazo Antionio",
            time: "11",
          },
          {
            id: v4(),
            event: "Yellow Card",
            type: "Home",
            by: "Lazo Antionio",
            time: "11",
          },
          {
            id: v4(),
            event: "Own Goal",
            type: "Away",
            by: "Lazo Antionio",
            time: "11",
          },
          {
            id: v4(),
            event: "Penalty",
            type: "Home",
            by: "Lazo Antionio",
            time: "11",
          },
          {
            id: v4(),
            event: "Own Goal",
            type: "Away",
            by: "Lazo Antionio",
            time: "11",
          },
          {
            id: v4(),
            event: "Own Goal",
            type: "Away",
            by: "Lazo Antionio",
            time: "11",
          },
          {
            id: v4(),
            event: "Own Goal",
            type: "Away",
            by: "Lazo Antionio",
            time: "11",
          },

          {
            id: v4(),
            event: "Own Goal",
            type: "Home",
            by: "Lazo Antionio",
            time: "11",
          },
          {
            id: v4(),
            event: "Own Goal",
            type: "Away",
            by: "Lazo Antionio",
            time: "11",
          },
          {
            id: v4(),
            event: "Own Goal",
            type: "Home",
            by: "Lazo Antionio",
            time: "11",
          },
        ],
      },
      {
        id: v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
      },
      {
        id: v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
      },
      {
        id: v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
      },
      {
        id: v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
      },
      {
        id: v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
      },
    ],
  },

  {
    id: v4(),
    leagueTitle: "Premier League",
    leagueAbbv: "GB",
    country: "England",
    leagueMatches: [
      {
        id: v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
      },
      {
        id: v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
      },
      {
        id: v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
      },
      {
        id: v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
      },
      {
        id: v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
      },
      {
        id: v4(),
        homeClub: "Barcelona",
        awayClub: "Real Madrid",
        homeClubScore: 1,
        awayClubScore: 0,
        time: "18:30",
        oneOdd: "2.50",
        xOdd: "3.25",
        twoOdd: "2.90",
      },
    ],
  },
];
