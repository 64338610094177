import React, { useContext } from "react";
import { MatchesContext } from "../../Context/MatchesContext";
import classes from "../LeaguesAndCategories/LeaguesAndCategories.module.css";
import { LinearProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { countries } from "../../Utilities/countries";
import { leagueImages } from "../../Utilities/leagueImages";
import { useState } from "react";
const SearchLeaguesAndCategories = ({ closeSideMenu }) => {
  // context
  const { leagueLoading, searchTerm, allCompetitions } =
    useContext(MatchesContext);

  // navigate
  const navigate = useNavigate();

  // State
  const [displayAllLeagues, setDisplayAllLeagues] = useState(false);

  return (
    <>
      {!leagueLoading && allCompetitions.length > 0 ? (
        <>
          {!displayAllLeagues && !searchTerm ? (
            <div className={classes.container}>
              {allCompetitions
                ?.filter((data) => {
                  return searchTerm.toLowerCase === ""
                    ? data
                    : data.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                        data?.country_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase());
                })
                ?.slice(1, 12)
                ?.map((datum) => {
                  const countryName = datum.country_name;

                  let countryAbbreviation = countries?.filter((data) => {
                    return data.name === countryName;
                  })[0]?.code;
                  return (
                    <div
                      key={datum.id}
                      className={classes.categoryOption}
                      id="categoryOption"
                      onClick={() => {
                        navigate(`/scores/${datum.id}/events`);
                        if (window.innerWidth < 767) {
                          closeSideMenu();
                        }
                      }}
                    >
                      <span>
                        <img
                          alt={datum.subCategoryTitle}
                          src={
                            countryAbbreviation
                              ? `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryAbbreviation}.svg`
                              : leagueImages.find((data) => {
                                  return datum.name
                                    .toLowerCase()
                                    .includes(data.name.toLowerCase());
                                })?.image
                          }
                          className={classes.hmm}
                        />
                      </span>
                      <span>{datum?.name}</span>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className={classes.container}>
              {allCompetitions
                ?.filter((data) => {
                  return searchTerm.toLowerCase === ""
                    ? data
                    : data.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                        data?.country_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase());
                })
                ?.map((datum) => {
                  const countryName = datum.country_name;

                  let countryAbbreviation = countries?.filter((data) => {
                    return data.name === countryName;
                  })[0]?.code;
                  return (
                    <div
                      key={datum.id}
                      className={classes.categoryOption}
                      id="categoryOption"
                      onClick={() => {
                        navigate(`/scores/${datum.id}/events`);
                        if (window.innerWidth < 767) {
                          closeSideMenu();
                        }
                      }}
                    >
                      <span>
                        <img
                          alt={datum.subCategoryTitle}
                          src={
                            countryAbbreviation
                              ? `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryAbbreviation}.svg`
                              : leagueImages.find((data) => {
                                  return datum.name
                                    .toLowerCase()
                                    .includes(data.name.toLowerCase());
                                })?.image
                          }
                          className={classes.hmm}
                        />
                      </span>
                      <span>{datum?.name}</span>
                    </div>
                  );
                })}
            </div>
          )}

          {!searchTerm && (
            <div
              className={classes.viewFull}
              onClick={() => {
                setDisplayAllLeagues((prevState) => {
                  return !prevState;
                });
              }}
            >
              {displayAllLeagues ? `Collapse` : `See more`}
            </div>
          )}
        </>
      ) : leagueLoading && allCompetitions.length < 1 ? (
        <div className={classes.progress}>
          <LinearProgress
            color="inherit"
            style={{ color: "#ffd91b", height: ".1rem" }}
          />
        </div>
      ) : (
        <div className={classes.noMatches}>Nothing to see here yet</div>
      )}
    </>
  );
};

export default SearchLeaguesAndCategories;
