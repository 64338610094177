import React, { useContext } from "react";
import Layout from "../Layout/Layout";
// import ScoreMobileAdSection from "./ScoreMobileAdSection";
import classes from "./ScorePageLayout.module.css";
import ScorePageNav from "./ScorePageNav";
import AdComponent from "../AdComponent/AdComponent";
import { AdContext } from "../../Context/AdContext";

const ScorePageLayout = (props) => {
  // context
  const { scoresPageAds } = useContext(AdContext);
  return (
    <Layout>
      <div className={classes.container}>
        <div className={classes.adSection}>
          {/* <ScoreAdSection /> */}
          <AdComponent
            adArray={scoresPageAds}
            section="300px-by-250px-at-the-top-of-the-home-page"
          />
        </div>
        {props.showNavSection && (
          <div className={classes.navSection}>
            <ScorePageNav showLeagueBasedNav={props.showLeagueBasedNav} />
          </div>
        )}
        <div className={classes.mobileAdSection}>
          {/* <ScoreMobileAdSection /> */}
          <AdComponent
            adArray={scoresPageAds}
            section="300px-by-250px-at-the-top-of-the-home-page-(mobile)"
          />
        </div>
        <div className={classes.body}>{props.children}</div>
      </div>
    </Layout>
  );
};

export default ScorePageLayout;
