import React, { useEffect } from "react";
import ScorePageLayout from "../../Components/ScorePageLayout/ScorePageLayout";
import { useContext } from "react";
import { LinearProgress } from "@mui/material";
import { TablesContext } from "../../Context/TablesContext";
import ScorePageTablesContainer from "./ScorePageTablesContainer";

const ScorePageTables = () => {
  // Context
  const { allLeagueTeables, getAllLeagueTables } = useContext(TablesContext);

  useEffect(() => {
    getAllLeagueTables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScorePageLayout showNavSection={true}>
      {allLeagueTeables.length < 1 ? (
        <LinearProgress
          color="inherit"
          style={{ color: "#ffd91b", height: ".1rem" }}
        />
      ) : (
        <>
          {allLeagueTeables?.map((table, i) => {
            return (
              <React.Fragment key={i}>
                <ScorePageTablesContainer
                  league={Object.values(
                    Object.values(table)[0].standing_participants
                  )}
                  name={table.league_name}
                  country_name={table.country_name}
                />
              </React.Fragment>
            );
          })}
        </>
      )}
    </ScorePageLayout>
  );
};

export default ScorePageTables;
