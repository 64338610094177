import Layout from "../../Components/Layout/Layout";
import { advertise } from "../../Utilities/aboutUsAndTerms";
import classes from "../AboutUs/AboutUs.module.css";

const Advertise = () => {
  return (
    <Layout notDisplaySide>
      <div className={classes.container}>
        <h4>Advertise</h4>
        <div className={classes.privacyNoticeContainer}>
          {advertise.map((data, i) => {
            return (
              <div key={i}>
                {data.paragraph && <p>{data?.paragraph}</p>}
                {data.children &&
                  data.children.map((datum, j) => {
                    return (
                      <ul key={j}>
                        <p>{datum.description}</p>
                        {datum.list.map((list, k) => {
                          return <li key={k}>{list}</li>;
                        })}
                      </ul>
                    );
                  })}
              </div>
            );
          })}
        </div>
        <div>
          <p className={classes.lastNote}>
            To advertise with us, kindly send a mail to{" "}
            <a href="mailto:admin@thenativesport.com">
              admin@thenativesport.com
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Advertise;
