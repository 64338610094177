import React, { useContext, useEffect } from "react";
import { MatchesContext } from "../../Context/MatchesContext";
import ScorePageLayout from "../../Components/ScorePageLayout/ScorePageLayout";
import classes from "./Favourites.module.css";
import FavoritedMatchContainer from "./FavoritedMatchContainer";

const Favourites = () => {
  // context
  const { favouritedMatches, setFavouritedMatches, allMatchesFromApi } =
    useContext(MatchesContext);

  // Update the favorite match on the frontend

  useEffect(() => {
    if (allMatchesFromApi.length > 0) {
      let allMatchValues = [];
      allMatchesFromApi.map((match) => {
        return Object.values(match).map((match2) => {
          return allMatchValues.push(match2);
        });
      });

      if (favouritedMatches.length > 0) {
        const allUpdatedMatches = favouritedMatches.map((favMatch) => {
          return allMatchValues.filter((data) => {
            return data.id === favMatch.id;
          });
        });

        setFavouritedMatches(allUpdatedMatches.flat());
      }
    }

    // eslint-disable-next-line
  }, [allMatchesFromApi]);

  return (
    <ScorePageLayout showNavSection={true}>
      <div className={classes.container}>
        <div className={classes.container}>
          <FavoritedMatchContainer
            leagueEvent={favouritedMatches}
            setLegueEvent={setFavouritedMatches}
            leagueAbbv={"GB"}
          />
        </div>
      </div>
    </ScorePageLayout>
  );
};

export default Favourites;
