import Layout from "../../Components/Layout/Layout";
import { cookiePolicy } from "../../Utilities/aboutUsAndTerms";
import classes from "../AboutUs/AboutUs.module.css";

const CookiePolicy = () => {
  return (
    <Layout notDisplaySide>
      <div className={classes.container}>
        <h4>Native Sport Cookie Policy</h4>
        <p>
          Native Sport is a sports media company passionate about reshaping
          sports coverage and delivering unparalleled content and experiences
          for fans across various platforms. This Cookie Policy explains how we
          use cookies and similar technologies ("cookies") on our websites for
          the purpose of enhancing your experience and delivering relevant
          advertisements.
        </p>

        <div className={classes.privacyNoticeContainer}>
          {cookiePolicy.map((data, i) => {
            return (
              <div key={i}>
                <h4>{data.header}</h4>
                {data.paragraph && <p>{data?.paragraph}</p>}
                {data.children &&
                  data.children.map((datum, j) => {
                    return (
                      <ul key={j}>
                        <p>{datum.description}</p>
                        {datum.list.map((list, k) => {
                          return <li key={k}>{list}</li>;
                        })}
                      </ul>
                    );
                  })}
              </div>
            );
          })}
          <div>
            <h4> Contact Us:</h4>

            <p>
              If you have any questions about this Cookie Policy, please contact
              us at
              <a href="mailto:admin@thenativesport.com">
                admin@thenativesport.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CookiePolicy;
