import { CircularProgress } from "@mui/material";
import { useContext } from "react";
import Error from "../../Components/Error/Error";
import { NewsContext } from "../../Context/NewsContext";
import classes from "./HomeEmailSIgnupContainer.module.css";

const HomeEmailSIgnupContainer = (props) => {
  // Context
  const { email, setEmail, subsscribeToNewsLetter, emailSubscribeObject } =
    useContext(NewsContext);

  return (
    <section className={classes.container}>
      <h4>Subscribe to our newsletter now</h4>
      <p>
        Get early access to new features, our events, fun trivia, and sports
        updates, straight to your inbox every week.
      </p>
      {emailSubscribeObject?.error && (
        <Error type="processing">{emailSubscribeObject?.error}</Error>
      )}
      {emailSubscribeObject?.data && (
        <Error type="success">{emailSubscribeObject?.data}</Error>
      )}
      <form>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="me@xyz.com"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            // props.onClick();
            if (email) {
              subsscribeToNewsLetter();
            }
          }}
          disabled={!email}
        >
          {emailSubscribeObject?.isLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            "Subscribe"
          )}
        </button>
        <p>
          Or click{" "}
          <a
            href="https://chat.whatsapp.com/DyQ0xdTZnlUHgBndpPqsdi"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{" "}
          to join our Whatsapp community
        </p>
      </form>
    </section>
  );
};

export default HomeEmailSIgnupContainer;
