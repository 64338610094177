import Layout from "../../Components/Layout/Layout";
import { privacyNotice } from "../../Utilities/aboutUsAndTerms";
import classes from "../AboutUs/AboutUs.module.css";

const PrivacyPolicy = () => {
  return (
    <Layout notDisplaySide>
      <div className={classes.container}>
        <h4>Native Sport Privacy Notice</h4>
        <p>
          Native Sport ("we," "us," or "our") is committed to protecting your
          privacy and providing you with a transparent and understandable
          overview of how we collect, use, and share your personal information
          in total compliance with the Nigeria Data Protection Regulation
          (NDPR). This Privacy Notice applies to all information we collect
          through our websites and any other platform or service we offer.
        </p>
        <div className={classes.privacyNoticeContainer}>
          {privacyNotice.map((data, i) => {
            return (
              <div key={i}>
                <h4>{data.header}</h4>
                {data.paragraph && <p>{data?.paragraph}</p>}
                {data.children &&
                  data.children.map((datum, j) => {
                    return (
                      <ul key={j}>
                        <p>{datum.description}</p>
                        {datum.list.map((list, k) => {
                          return <li key={k}>{list}</li>;
                        })}
                      </ul>
                    );
                  })}
              </div>
            );
          })}
          <div>
            <h4> Contact Us:</h4>

            <p>
              If you have any questions about this Privacy Notice, please
              contact us at{" "}
              <a href="mailto:admin@thenativesport.com">
                admin@thenativesport.com
              </a>
            </p>
            <p className={classes.lastNote}>
              This Privacy Notice was last updated on January 5, 2024.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
