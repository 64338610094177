import React, { useContext, useState } from "react";
import classes from "./LiveTables.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { SocialIcon } from "react-social-icons";
import { useEffect } from "react";
import { TablesContext } from "../../Context/TablesContext";
import TeamLogo from "../../Components/TeamLogo/TeamLogo";
import { useNavigate } from "react-router-dom";

const LiveTables = () => {
  const [combinedTable, setCombinedTable] = useState([]);
  const [currentTableIndex, setCurrentTableIndex] = useState(0);
  const [displayFullTable, setDisplayFullTable] = useState(false);

  const {
    premierLeagueTable,
    spanishLeagueTable,
    frenchLeagueTable,
    germanLeagueTable,
    italianLeagueTable,
  } = useContext(TablesContext);

  useEffect(() => {
    if (
      premierLeagueTable.length > 0 &&
      spanishLeagueTable.length > 0 &&
      frenchLeagueTable.length > 0 &&
      germanLeagueTable.length > 0 &&
      italianLeagueTable.length > 0
    ) {
      setCombinedTable([
        premierLeagueTable,
        spanishLeagueTable,
        frenchLeagueTable,
        germanLeagueTable,
        italianLeagueTable,
      ]);
    }

    // eslint-disable-next-line
  }, [
    premierLeagueTable,
    spanishLeagueTable,
    italianLeagueTable,
    germanLeagueTable,
    frenchLeagueTable,
  ]);

  // Utilitues

  const currentTable = combinedTable[currentTableIndex];

  const handleClickNext = () => {
    if (currentTableIndex < combinedTable.length - 1) {
      setCurrentTableIndex(currentTableIndex + 1);
    }
  };

  const handleClickPrev = () => {
    if (currentTableIndex > 0) {
      setCurrentTableIndex(currentTableIndex - 1);
    }
  };

  // Routing
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div>Live Table</div>
        <div>
          <i
            onClick={handleClickPrev}
            style={
              currentTableIndex === 0 ? { opacity: "0.5" } : { opacity: "1" }
            }
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </i>
          <span>
            {currentTableIndex === 0
              ? "Premier League"
              : currentTableIndex === 1
              ? "La Liga"
              : currentTableIndex === 2
              ? "Ligue 1"
              : currentTableIndex === 3
              ? "BundesLiga"
              : currentTableIndex === 4
              ? "Serie A"
              : null}
          </span>
          <i onClick={handleClickNext}>
            <FontAwesomeIcon
              icon={faAngleRight}
              style={
                currentTableIndex === 4 ? { opacity: "0.5" } : { opacity: "1" }
              }
            />
          </i>
        </div>
      </div>
      <div className={classes.liveTableHeader}>
        <div>Team</div>
        <div>P</div>
        <div>W</div>
        <div>L</div>
        <div>D</div>
        <div>Pts</div>
      </div>
      <div className={classes.liveTableContent}>
        {!displayFullTable &&
          currentTable
            ?.sort((a, b) => {
              return a.rank - b.rank;
            })
            ?.slice(0, 10)
            ?.map((data) => {
              return (
                <div className={classes.liveTableBody} key={data.id}>
                  <div>
                    <span>
                      <TeamLogo id={data?.participantFK} />
                    </span>
                    <span
                      onClick={() => {
                        navigate(`/team/${data?.participantFK}/players`);
                      }}
                    >
                      {data.participant?.name.length > 12
                        ? `${data.participant?.name.slice(0, 10)}...`
                        : `${data.participant?.name}`}
                    </span>
                  </div>

                  <div>{data?.standing_data[4].value}</div>
                  <div>{data?.standing_data[5].value}</div>
                  <div>{data?.standing_data[6].value}</div>
                  <div>{data?.standing_data[3].value}</div>
                  <div>{data?.standing_data[0].value}</div>
                </div>
              );
            })}

        {displayFullTable &&
          currentTable
            ?.sort((a, b) => {
              return a.rank - b.rank;
            })
            ?.map((data) => {
              return (
                <div className={classes.liveTableBody} key={data.id}>
                  <div>
                    <span>
                      <TeamLogo id={data?.participantFK} />
                    </span>
                    <span
                      onClick={() => {
                        navigate(`/team/${data?.participantFK}/players`);
                      }}
                    >
                      {data.participant?.name.length > 12
                        ? `${data.participant?.name.slice(0, 10)}...`
                        : `${data.participant?.name}`}
                    </span>
                  </div>

                  <div>{data?.standing_data[4].value}</div>
                  <div>{data?.standing_data[5].value}</div>
                  <div>{data?.standing_data[6].value}</div>
                  <div>{data?.standing_data[3].value}</div>
                  <div>{data?.standing_data[0].value}</div>
                </div>
              );
            })}
      </div>
      <div className={classes.viewFull}>
        <div>
          <span
            onClick={() => {
              setDisplayFullTable(!displayFullTable);
            }}
          >
            {displayFullTable ? "Collapse table" : "View full table"}
          </span>
          <span>
            <FontAwesomeIcon icon={faAngleRight} />
          </span>
        </div>
        <div>
          <SocialIcon
            url="https://chat.whatsapp.com/DyQ0xdTZnlUHgBndpPqsdi"
            bgColor="#DADADA"
            style={{ width: "20px", height: "20px" }}
          />
          <SocialIcon
            url="https://twitter.com/Sport__Dm?t=_U5pMvVBRWyGK5jsa6qBRQ&s=09"
            bgColor="#DADADA"
            style={{ width: "20px", height: "20px" }}
          />
          <SocialIcon
            url="https://www.instagram.com/thesportdm?igsh=YzVkODRmOTdmMw=="
            bgColor="#DADADA"
            style={{ width: "20px", height: "20px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default LiveTables;
