import React, { createContext, useContext, useState } from "react";
import { v4 } from "uuid";
import { MatchesContext } from "./MatchesContext";
import axios from "axios";
import moment from "moment/moment";

export const MatchesContextAlt = createContext();

const MatchesContextAltProvider = (props) => {
  const {
    setEventIncidents,
    setSecondParticipantResults,
    setIsSendingRequest,
    setEventParticipants,
    setFirstParticipantResults,
    currentTime,
    setSpecificmatchData,
    setStadium,
    setMatchCommentary,
    setFirstParticipantScopeResults,
    setSecondParticipantScopeResults,
    setAllMatchesFromApi,
    includeLive,
  } = useContext(MatchesContext);

  // const [allMatchesFromApi, setAllMatchesFromApi] = useState([]);
  const [requiredDate] = useState(moment().format(moment.HTML5_FMT.DATE));

  const formattedDate = moment(requiredDate).format(moment.HTML5_FMT.DATE);
  const includeLiveQueryParam = includeLive ? "&status_type=inprogress" : "";

  const fetchSpecificMatchEventsAlt = (id) => {
    let firstParticipantIncidents = [];
    let secondParticipantIncidents = [];
    // let firstParticipantScopeResult = [];
    // let secondParticipantScopeResult = [];

    axios
      .get(
        `https://eapi.enetpulse.com/event/details/?id=${id}&includeLineups=yes&includeEventProperties=yes&includeTeamProperties=yes&includeIncidents=yes&includeExtendedResults=yes&includeProperties=yes&includeLivestats=yes&includeVenue=yes&includeCountryCodes=yes&includeFirstLastName=no&includeReference=yes&includeObjectParticipants=yes&includeEventIncidentRelation=yes&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
      )
      .then((res) => {
        setSpecificmatchData(res.data.event[id]);
        setEventParticipants(
          Object.values(res.data.event[id].event_participants)
        );

        // First participants results keys
        let firstParticipantResultKeys = [];
        let secondParticipantResultKeys = [];

        // Scope results (if its a two legged knockout staged match)
        // let firstParticipantScopeResultKey = [];
        // let secondParticipantScopeResultKey = [];

        // stadium
        setStadium(Object.values(res.data.event[id].venue)[0].name);

        //  results
        firstParticipantResultKeys = Object.keys(
          Object.values(res.data.event[id].event_participants)[0].result || []
        );

        for (let i = 0; i < firstParticipantResultKeys.length; i++) {
          const currentResult = Object.values(
            res.data.event[id].event_participants
          )[0]?.result[firstParticipantResultKeys[i]];
          setFirstParticipantResults((prevState) => [
            ...prevState,
            currentResult,
          ]);
        }

        firstParticipantIncidents = Object.values(
          Object.values(Object.values(res.data.event)[0].event_participants)[0]
            .incident || []
        ).map((data) => {
          return { ...data, tag: "home", newFrontendId: v4() };
        });
        secondParticipantIncidents = Object.values(
          Object.values(Object.values(res.data.event)[0].event_participants)[1]
            .incident
        ).map((data) => {
          return { ...data, tag: "away", newFrontendId: v4() };
        });

        setEventIncidents([
          ...firstParticipantIncidents,
          ...secondParticipantIncidents,
        ]);

        secondParticipantResultKeys = Object.keys(
          Object.values(res.data.event[id].event_participants)[1]?.result
        );

        for (let i = 0; i < secondParticipantResultKeys.length; i++) {
          const currentResult = Object.values(
            res.data.event[id].event_participants
          )[1]?.result[secondParticipantResultKeys[i]];
          setSecondParticipantResults((prevState) => [
            ...prevState,
            currentResult,
          ]);
        }

        // First participant scope results

        let firstParticipantsScopeResults = [];
        if (
          Object.values(Object.values(res.data.event)[0]?.event_participants)[0]
            .scope_result
        ) {
          firstParticipantsScopeResults = Object.values(
            Object.values(
              Object.values(res?.data?.event)[0]?.event_participants
            )[0]?.scope_result
          );
        } else {
          firstParticipantsScopeResults = [];
        }

        setFirstParticipantScopeResults(firstParticipantsScopeResults);

        // Second Particant Scope results
        let secondParticipantsScopeResults = [];
        if (
          Object.values(
            Object.values(
              Object.values(res?.data?.event)[0]?.event_participants
            )[1]?.scope_result
          )
        ) {
          secondParticipantsScopeResults = Object.values(
            Object.values(
              Object.values(res?.data?.event)[0]?.event_participants
            )[1]?.scope_result
          );
        } else {
          secondParticipantsScopeResults = [];
        }

        setSecondParticipantScopeResults(secondParticipantsScopeResults);

        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
      });
  };

  const fetchMatchCommentaryAlt = (id) => {
    axios
      .get(
        `https://eapi.enetpulse.com/event/commentaries/?id=${id}&limit=100&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
      )
      .then((res) => {
        setMatchCommentary(Object.values(res.data.event)[0].event_incident);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllLeaguesANdEventsAltAtIntervals = async () => {
    try {
      const res = await axios.get(
        `https://eapi.enetpulse.com/tournament_template/list/?sportFK=1&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
      );

      // Fetch league matches for all events
      const allResponses = [];

      const promises = Object.values(res.data.tournament_templates).map(
        (league) => {
          return axios
            .get(
              `https://eapi.enetpulse.com/event/daily/?date=${formattedDate}${includeLiveQueryParam}&live=yes&includeVenue=yes&includeEventProperties=yes&includeCountryCodes=no&tz=${currentTime}&tournament_templateFK=${league.id}&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${currentTime}`
            )
            .then((response) => {
              if (Object.values(response.data.events).length > 0) {
                return response.data.events;
              } else {
                return {};
              }
            })
            .catch((error) => {
              return;
            });
        }
      );

      try {
        const responses = await Promise.all(promises);

        allResponses.push(...responses);
      } catch (error) {
        return;
      }

      const filteredResponses = allResponses.filter((data) => {
        return Object.keys(data).length > 0;
      });

      // }

      setAllMatchesFromApi(filteredResponses);
      return;
    } catch (err) {}
  };

  return (
    <MatchesContextAlt.Provider
      value={{
        fetchSpecificMatchEventsAlt,
        fetchMatchCommentaryAlt,
        // allMatchesFromApi,
        getAllLeaguesANdEventsAltAtIntervals,
      }}
    >
      {props.children}
    </MatchesContextAlt.Provider>
  );
};

export default MatchesContextAltProvider;
