import React, { useState } from "react";
import classes from "./ScorePageMatches.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faStar as starr,
} from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MatchesContext } from "../../Context/MatchesContext";
import TeamLogo from "../../Components/TeamLogo/TeamLogo";
import goalnownowIcon from "../../Assets/Images/goalnownowIcon.png";
import { scrollToTop } from "../../Utilities/scrollFunctions";

const LeagueMatchContainer = (props) => {
  // Context
  const { showOdds, setFavouritedMatches, favouritedMatches } =
    useContext(MatchesContext);

  //   navigate
  const navigate = useNavigate();

  const getUserResults = (umbrellaArray) => {
    let firstParticipantResult = [];
    let secondParticipantResult = [];

    if (umbrellaArray && props.leagueEvent) {
      let eventParticipants = Object.values(umbrellaArray?.event_participants);

      //  results
      let firstParticipantResultKeys = Object.keys(
        eventParticipants[0]?.result
      );

      for (let i = 0; i < firstParticipantResultKeys.length; i++) {
        const currentResult =
          eventParticipants[0]?.result[firstParticipantResultKeys[i]];
        firstParticipantResult.push(currentResult);
      }
      let secondParticipantResultKeys = Object.keys(
        eventParticipants[1]?.result
      );

      for (let i = 0; i < secondParticipantResultKeys.length; i++) {
        const currentResult =
          eventParticipants[1]?.result[secondParticipantResultKeys[i]];
        secondParticipantResult.push(currentResult);
      }

      return { firstParticipantResult, secondParticipantResult };
    }
  };

  const date = new Date().toJSON().slice(0, 10);

  const activeMatchStyleHandler = (datum) => {
    if (
      datum?.status_type === "inprogress" &&
      datum?.startdate.slice(0, 10) === date
    ) {
      return { backgroundColor: "#ffd91b", visibility: "visible" };
    }
    if (
      datum?.status_type === "finished" &&
      datum?.startdate.slice(0, 10) === date
    ) {
      return { backgroundColor: "#05a156", visibility: "visible" };
    } else {
      return { visibility: "hidden" };
    }
  };

  const [display, setDisplay] = useState(true);

  return (
    <>
      {props?.leagueEvent?.length > 0 && (
        <div className={classes.leagueData} key={props.key}>
          <div
            className={classes.leagueHeader}
            onClick={() => {
              setDisplay(!display);
            }}
          >
            <div className={classes.leagueHeaderdata}>
              <div>
                <img
                  alt={props?.leagueEvent[0]?.tournament_stage_name}
                  src={
                    // props?.leagueEvent[0]?.tournament_stage_name
                    // ? leagueImages.find((data) => {
                    //     return props?.leagueEvent[0]?.tournament_stage_name
                    //       .toLowerCase()
                    //       .includes(data.name.toLowerCase());
                    //   })?.image
                    // :
                    goalnownowIcon
                  }
                  className={classes.hmm}
                />
              </div>

              <div>
                <span>{props?.leagueEvent[0]?.tournament_stage_name}</span>
                <span>{props?.leagueEvent[0]?.tournament_template_name}</span>
              </div>
            </div>
            <div
              className={classes.leagueRouter}
              style={
                display
                  ? {
                      transform: "rotate(0deg)",
                      transition: "all .3s ease-in-out",
                    }
                  : {
                      transform: "rotate(90deg)",
                      transition: "all .3s ease-in-out",
                    }
              }
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <div
            className={classes.leagueGames}
            style={
              display
                ? {
                    maxHeight: "200vh",
                    overflowY: "hidden",
                    transition: "all .3s ease-in-out",
                  }
                : {
                    maxHeight: "0vh",
                    overflowY: "hidden",
                    transition: "all .3s ease-in-out",
                  }
            }
          >
            {props.leagueEvent?.map((datum) => {
              const results = getUserResults(datum);
              return (
                <div className={classes.leagueGamesOuter} key={datum.id}>
                  <div className={classes.leagueGame}>
                    <div
                      className={classes.liveIndicator}
                      style={activeMatchStyleHandler(datum)}
                    ></div>

                    <div
                      className={classes.time}
                      onClick={() => {
                        navigate(`/scores/${datum.id}/summary`);
                        scrollToTop();
                      }}
                    >
                      {datum.status_type === "finished" &&
                      Object.values(datum.elapsed)[0].elapsed === "90" ? (
                        "FT"
                      ) : datum.status_type === "inprogress" ? (
                        <div className={classes.timeLeft}>
                          {`${Object.values(datum.elapsed)[0].elapsed}'`}
                        </div>
                      ) : datum.status_type === "halftime" ? (
                        "HT"
                      ) : (
                        <div className={classes.matchTime}>
                          {datum.startdate.slice(11, 16)}
                        </div>
                      )}
                    </div>
                    <div
                      className={classes.clubNameSection}
                      onClick={() => {
                        navigate(`/scores/${datum.id}/summary`);
                        scrollToTop();
                      }}
                      style={{ width: "100%" }}
                    >
                      <div>
                        <span>
                          <TeamLogo
                            id={
                              Object.values(datum?.event_participants)[0]
                                ?.participantFK
                            }
                          />
                        </span>
                        <span>
                          {
                            Object.values(datum?.event_participants)[0]
                              ?.participant?.name
                          }
                        </span>
                      </div>
                      <div>
                        <span>
                          <TeamLogo
                            id={
                              Object.values(datum?.event_participants)[1]
                                .participantFK
                            }
                          />
                        </span>
                        <span>
                          {
                            Object.values(datum?.event_participants)[1]
                              ?.participant?.name
                          }
                        </span>
                      </div>
                    </div>
                    {showOdds && (
                      <div className={classes.oddsSection}>
                        <div>
                          <span>1</span>
                          <span>{0}</span>
                        </div>
                        <div>
                          <span>x</span>
                          <span>{0}</span>
                        </div>
                        <div>
                          <span>2</span>
                          <span>{0}</span>
                        </div>
                      </div>
                    )}
                    <div className={classes.scoresAndFavorites}>
                      {datum.status_type !== "notstarted" && (
                        <div>
                          <span>
                            {results?.firstParticipantResult[1].value}
                          </span>
                          <span>
                            {results?.secondParticipantResult[1].value}
                          </span>
                        </div>
                      )}

                      <div>
                        {favouritedMatches?.find((data) => {
                          return data.id === datum.id;
                        }) ? (
                          <FontAwesomeIcon
                            icon={starr}
                            onClick={() => {
                              const newFeatured = favouritedMatches?.filter(
                                (data) => {
                                  return data.id !== datum.id;
                                }
                              );
                              setFavouritedMatches(newFeatured);
                            }}
                            style={{ color: "#ffd91b", cursor: "pointer" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faStar}
                            onClick={() => {
                              setFavouritedMatches((prevState) => [
                                ...prevState,
                                datum,
                              ]);
                            }}
                            style={{ color: "#ffd91b", cursor: "pointer" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.mobileOddSection}>
                    {showOdds && (
                      <div className={classes.oddsSectionMobile}>
                        <div>
                          <span>1</span>
                          <span>1.5</span>
                        </div>
                        <div>
                          <span>x</span>
                          <span>1.5</span>
                        </div>
                        <div>
                          <span>2</span>
                          <span>1.5</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default LeagueMatchContainer;
