import React, { useContext } from "react";
import classes from "./Headlines.module.css";
import { NewsContext } from "../../Context/NewsContext";

const Headlines = () => {
  // context
  const { featuresNews } = useContext(NewsContext);
  return (
    <div className={classes.container}>
      <div>
        <span
          onClick={() => {
            window.open(
              `https://sportdm.com/home/${
                featuresNews.filter((data) => {
                  return !data.isActive;
                })[0].uri
              }`
            );
          }}
        >
          {
            featuresNews.filter((data) => {
              return !data.isActive;
            })[0]?.subject[1]?.name
          }
        </span>
        <span
          onClick={() => {
            window.open(
              `https://sportdm.com/home/${
                featuresNews.filter((data) => {
                  return !data.isActive;
                })[0].uri
              }`
            );
          }}
        >
          {
            featuresNews.filter((data) => {
              return !data.isActive;
            })[0]?.headline
          }
        </span>
      </div>
      <div>
        <span
          onClick={() => {
            window.open(
              `https://sportdm.com/home/${
                featuresNews.filter((data) => {
                  return !data.isActive;
                })[1].uri
              }`
            );
          }}
        >
          {
            featuresNews.filter((data) => {
              return !data.isActive;
            })[1]?.subject[1]?.name
          }
        </span>
        <span
          onClick={() => {
            window.open(
              `https://sportdm.com/home/${
                featuresNews.filter((data) => {
                  return !data.isActive;
                })[1].uri
              }`
            );
          }}
        >
          {
            featuresNews.filter((data) => {
              return !data.isActive;
            })[1]?.headline
          }
        </span>
      </div>
    </div>
  );
};

export default Headlines;
