import React, { useContext } from "react";
import classes from "./BreakingNews.module.css";
import { NewsContext } from "../../Context/NewsContext";

const BreakingNews = () => {
  // context
  const { featuresNews } = useContext(NewsContext);

  return (
    <div className={classes.container}>
      {featuresNews?.slice(0, 3).map((news) => {
        return (
          <div
            className={news.isActive ? classes.active : null}
            key={news.uri}
            onClick={() => {
              window.open(`https://sportdm.com/#/home/${news.uri}`);
            }}
          >
            <img src={news?.picture} alt="Breaking News" />
          </div>
        );
      })}
      <div className={classes.textSection}>
        <span className={classes.category}>
          {
            featuresNews?.find((data) => {
              return data.isActive;
            })?.subject[1]?.name
          }
        </span>
        <span
          className={classes.text}
          onClick={() => {
            window.open(
              `https://sportdm.com/home/${
                featuresNews.find((data) => {
                  return data.isActive;
                }).uri
              }`
            );
          }}
        >
          {
            featuresNews?.find((data) => {
              return data?.isActive;
            })?.headline
          }
        </span>
      </div>
    </div>
  );
};

export default BreakingNews;
